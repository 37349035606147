import { Link } from 'react-router-dom'
import './PastProjects.css'
function PastProject(){
    return(
        <div>
            <div className="projectLinks">
                <h1>Past Projects</h1>
                <Link to="/myvideos">Videos</Link>
                <Link to="/myclothes">Clothes</Link>
                <Link to="/mymusic">Music</Link> 
                <Link to="/about">Back</Link>
            </div>
        </div>
    )
}

export default PastProject