import { useState } from 'react';
import './SendEmailPage.css'; 
import logo from "./Icons/logo.png"
import emailjs from '@emailjs/browser'
import { useEffect } from 'react';

function SendEmailPage() {
    const name = 'lil bro'
    const email = 'littlebrotherblog1@gmail.com'
    const [message, setMessage] = useState('');
    const [emailList, setEmailList] = useState([])

    // get list of emails from the backend
    useEffect(() => {
        const fetchEmailList = async () => {
            try {
                const response = await fetch('https://lilbrobackend-4e1f701d20d5.herokuapp.com/getEmailList');

                if (!response.ok) {
                    throw new Error('Failed to get data');
                }
                
                const data = await response.json();
                setEmailList(data);
            } catch (error) {
                console.error(error.message);
            }
        };
        // Call the fetch function when the component mounts
        fetchEmailList(); 
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();
        const serviceId = 'service_f05p6hc';
        const templateId = 'template_26123cy';
        const publicKey = 'QgQslgOwEI6eYPb-K';
    
        console.log('hey there');
        console.log(emailList);
    
        // Loop through each email in the emailsList
        for (const to_email of emailList) {
            try {
                const templateParams = {
                    from_name: name,
                    from_email: email,
                    to_name: to_email,
                    message: message
                };
    
                // Send email
                const response = await emailjs.send(serviceId, templateId, templateParams, publicKey);
                console.log('Email sent to:' + to_email, response);
            } catch (error) {
                console.error('Error sending email:', error);
            }
        }
    
        // Clear message after sending all emails
        alert("Sent!")
        setMessage('');
    }
    
    return (
        <div className="form-container2">
            <form className="form2" onSubmit={handleSubmit}>
                <img src={logo} alt='LB logo' className='logo2'/>
                <textarea
                cols="30"
                rows="10"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                />
                <button type="submit">Send email</button>
            </form>
        </div>
    );
}

export default SendEmailPage;
