import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './AdminPage.css'; // Import the CSS file
import bomb from "./Icons/bomb.png"
import { useEffect } from 'react';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false); 
  const [dbUser, setDbUser] = useState("");
  const [dbPass, setDbPass] = useState("")

  // get username and password from database  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://lilbrobackend-4e1f701d20d5.herokuapp.com/signin');
  
        if (!response.ok) {
            throw new Error('Failed to get data');
        }
        
        let data = await response.json();
        data = JSON.parse(data);
        setDbUser(data[0].username);
        setDbPass(data[0].password);
      } catch (error) {
        console.error(error.message);
      }
    };
  
    fetchData();
  
  }, []);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  function handleSubmit(event){
    event.preventDefault();
    // Check if username and password match the criteria
    if (username === dbUser && password === dbPass) {
      // Set loggedIn state to true
      setLoggedIn(true);
    } else {
      console.log(username)
      console.log(password)
      // Display error message or perform other actions for failed login attempt
      alert('BOOM!!!!!');
    }
  };

  return (
    <div className="container">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <img src={bomb} alt="bombpic"></img>
          <div>
            <label>
              <input type="text" value={username} onChange={handleUsernameChange} />
            </label>
          </div>
          <div>
            <label>
              <input type="password" value={password} onChange={handlePasswordChange}/>
            </label>
          </div>
          <div>
            <button type="submit">Self Destruct</button>
          </div>
        </form>
        {/* Redirect to post page if loggedIn is true */}
        {loggedIn && <Link to="/post">Post to blog</Link>}
        <div className='spacedDiv'/>
        {loggedIn && <Link to="/postitem">Post an item</Link>}
        <div className='spacedDiv'/>
        {loggedIn && <Link to="/sendEmail">Send an Email</Link>}
      </div>
    </div>
  );

};

export default LoginPage;


