import React from "react";
import "./RadioPage.css";

function RadioPage() {
    return (
        <div className="radio-page-container">
            <h1>Lil Bro Radio!</h1>
            <div className="radio-page-div">Discover new music, artists, genres, or just enjoy songs you already know!</div>
            <img 
                src="https://media.tenor.com/i-QaRg5FSsQAAAAM/pokemon.gif" 
                alt="Animated GIF"
                className="radio-page-gif"
            />
            <a href="https://music.apple.com/us/playlist/lil-bro-radio/pl.u-oZyllEesRz6jZXL" className="radio-page-link">Apple Music</a>
            <a href="https://open.spotify.com/playlist/6cTRx2C1a3Z0v96q3Dptyl?si=6Lv7yKNcSUecMgb-gcvVwQ&pi=u-UhfZ0KzgSL-V" className="radio-page-link">Spotify</a>
        </div>
    );
}

export default RadioPage;
