import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './CustomerInfoPage.css';
import emailjs from '@emailjs/browser';
import logo from "./Icons/logo.png";

function CustomerInfo() {
    const location = useLocation();
    
    // Destructure title and price from location.state
    const { title, price } = location.state || {}; // Default values to avoid undefined

    const [formData, setFormData] = useState({
        name: '',
        address: '',
        state: '',
        city: '',
        zipCode: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    async function handlePaymentSubmit() {
        const serviceId = 'service_f05p6hc';
        const templateId = 'template_h187bsl';
        const publicKey = 'QgQslgOwEI6eYPb-K';
        const message = `
            Item: ${title}
            Price: $${price}
            Full Name: ${formData.name}
            Address: ${formData.address}
            State: ${formData.state}
            City: ${formData.city}
            Zip: ${formData.zipCode}
        `;

        try {
            const templateParams = {
                to_name: 'littlebrotherblog1@gmail.com',
                message: message
            };

            const response = await emailjs.send(serviceId, templateId, templateParams, publicKey);
            console.log('Email sent to:' + templateParams.to_name, response);
        } catch (error) {
            console.error('Error sending email:', error);
        }

        setFormData({
            name: '',
            address: '',
            state: '',
            city: '',
            zipCode: ''
        });
        // alert("Sent!");
    }

    const handleAddressSubmit = async (e) => {
        e.preventDefault();
        
        // Perform PayPal processing logic here
        
        await handlePaymentSubmit(); // Call handlePaymentSubmit after PayPal logic
    };

    return (
        <div className="form-container-shop">
            <form onSubmit={handleAddressSubmit}>
                <img src={logo} alt='logo' className='logo' />
                <label>
                    Full Name:
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </label>
                <br />
                <label>
                    Address:
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                    />
                </label>
                <br />
                <label>
                    State:
                    <input
                        type="text"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        required
                    />
                </label>
                <br />
                <label>
                    City:
                    <input
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        required
                    />
                </label>
                <br />
                <label>
                    Zip Code:
                    <input
                        type="text"
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleChange}
                        required
                    />
                </label>
                <br />
                <button type="submit" className='shop-button'>I'm sure I want to pay ${price}</button>
            </form>
        </div>
    );
}

export default CustomerInfo;


