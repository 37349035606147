import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './ShopPage.css';

function ShopPage() {
  const [shopData, setShopData] = useState(null);
  const [error, setError] = useState(null);
  const [shopIndex, setShopIndex] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/shop') {
      setShopIndex(null);
    }
  }, [location]);

  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const response = await fetch('https://lilbrobackend-4e1f701d20d5.herokuapp.com/getShop');
        if (!response.ok) {
          throw new Error('Failed to get data');
        }
        const data = await response.json();
        setShopData(data.reverse());
      } catch (error) {
        setError(error.message);
      }
    };
    fetchShopData();
  }, []);

  const handleShopClick = (index) => {
    setShopIndex(index);
  };

  if (!shopData && !error) {
    return (
      <div className="loading-wrapper">
        <div className="loading-message">
          <span>L</span>
          <span>O</span>
          <span>A</span>
          <span>D</span>
          <span>I</span>
          <span>N</span>
          <span>G</span>
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  let selectedShopItem = null;
  if (shopIndex !== null) {
    selectedShopItem = JSON.parse(shopData[shopIndex]);
  }

  return (
    <div className="shop-page-container">
      <h1 className="horror-font">Lil Bro Shop!</h1>
      {shopIndex !== null ? (
        <div className="shop-item-details">
          <h1 className="shop-item-title">{selectedShopItem.title}</h1>
          <div className="shop-itemframe">
            <img
              src={selectedShopItem.image}
              alt={selectedShopItem.title}
              className="shop-item-image"
            />
          </div>
          {selectedShopItem.backImage && (
            <div className="shop-itemframe">
              <img
                src={selectedShopItem.backImage}
                alt={selectedShopItem.title}
                className="shop-item-image"
              />
            </div>
          )}

          {/* Render description */}
          {selectedShopItem.description && (
            <div className="shop-description">
              {selectedShopItem.description.split('\n').map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          )}

          {/* Render price */}
          {selectedShopItem.price && (
            <div className="shop-price">
              <p>${parseFloat(selectedShopItem.price).toFixed(2)}</p>
            </div>
          )}

          {/* Render size */}
          {selectedShopItem.size && (
            <div className="shop-size">
              {selectedShopItem.size.split('\n').map((sizeLine, index) => (
                <p key={index}>{sizeLine}</p>
              ))}
            </div>
          )}

          {/* Conditional rendering of Buy button or Not for sale message */}
          {selectedShopItem.status === "true" ? (
            <Link 
              to= "/customerinfo"
                state= {{
                  title: selectedShopItem.title,
                  price: parseFloat(selectedShopItem.price).toFixed(2) // Ensure price is formatted as a float
                }} 
              className="buy-button"
            >
              Buy
            </Link>
          ) : (
            <div className="not-for-sale">Not for sale :'(</div>
          )}

          <Link to="/shop" className='shop-back-button'>Back</Link>
        </div>
      ) : (
        <div className="shop-grid-container">
          {shopData.map((item, index) => {
            const parsedItem = JSON.parse(item);
            return (
              <div className="shop-grid-item" key={index} onClick={() => handleShopClick(index)}>
                <img
                  src={parsedItem.image}
                  alt={parsedItem.title}
                  className="shop-item-image"
                />
                <h3 className="shop-item-title">{parsedItem.title}</h3>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ShopPage;
