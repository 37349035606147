import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./SportsPage.css"

function SportsPage() {
  const [sportsData, setSportsData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedSportIndex, setSelectedSportIndex] = useState(null);

  useEffect(() => {
    const fetchSportsData = async () => {
      try {
        const response = await fetch('https://lilbrobackend-4e1f701d20d5.herokuapp.com/getSports');

        if (!response.ok) {
          throw new Error('Failed to get data');
        }
        
        const data = await response.json();
        setSportsData(data.reverse());
      } catch (error) {
        setError(error.message);
      }
    };
    fetchSportsData();
  }, []);
  
  const handleSportClick = (index) => {
    setSelectedSportIndex(index);
  };
  
  // Render loading state while fetching data
  if (!sportsData && !error) {
    return <div>Loading...</div>;
  }

  // Render error message if fetching data failed
  if (error) {
    return <div>Error: {error}</div>;
  }
  // Render Sports data
  return (
    <div className="centerSports">
      {selectedSportIndex !== null ? (
        <div>
          <h1>{JSON.parse(sportsData[selectedSportIndex]).title}</h1>
          <div className='sportsframe'>
            <img
              src={JSON.parse(sportsData[selectedSportIndex]).image}
              alt={JSON.parse(sportsData[selectedSportIndex]).title}
            />
          </div>
          <div className="description">
            {JSON.parse(sportsData[selectedSportIndex])
              .description
              .split('\n')
              .map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
          </div>
          {JSON.parse(sportsData[selectedSportIndex]).link !== '' && (
            <a href={JSON.parse(sportsData[selectedSportIndex]).link} alt='More Information'>
              Click here for more!
            </a>
          )}
          <div/>
          <button onClick={() => setSelectedSportIndex(null)}>Back</button>
        </div>
      ) : (
        <div>
          {sportsData.map((sport, index) => {
            const parsedSport = JSON.parse(sport);
            return (
              <button className='sportsButton' key={index} onClick={() => handleSportClick(index)}>
                {parsedSport.title}
              </button>
            );
          })}
          <Link to='/'>Back</Link>
        </div>
      )}
    </div>
  );
  
}

export default SportsPage;

