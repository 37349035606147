import './AboutMe.css'
import cate from './Icons/cate.jpeg'
import { Link } from 'react-router-dom'
function AboutMe(){
    return(
        <div>
            <h2 className='centerDiv'>Who?</h2>
            <div className='catecontainer'>
                <img src={cate} alt='cate' className='catelogo'></img>  
            </div>
            <div className='centerDiv'>
                My name is Gabe Terrazas! A computer science student with a passion for other things too!
            </div>
            <h2 className='centerDiv'>What?</h2>
            <div className='centerDiv'>
                The purpose of the blog is to talk about anything related to the topics in the home page.
                It might be a hot take or just something random, maybe even something interesting!
                I hope to update as often as possible and if you add your email, you can get a weekly recap of my posts! 
            </div>
            <h2 className='centerDiv'>Why?</h2>
            <div className='centerDiv'>
                I started this blog to practice my computer science skills in a way that didn't feel like homework.
                I think making a project you like and care about is the best way to learn effectively, and I can honestly say I learned so many new things working on this.
            </div>
            <h2 className='centerDiv'>How?</h2>
            <div className='centerDiv'>
                I made the entire website from scratch, using React.js for the frontend and flask for the backend, which I had some experience with in the past.
                I had to learn how to use MongoDB, Netlify, Emailjs, and Heroku which was a bit tedious but absolutely worth it. 
                The main thing I learned from this experience is that in order to be a better developer, you have to get used to reading documentation.
                Opening a bunch of links and finding the solution to your problem is the only way you're gonna get better at coding,
                it can seem boring to read a bunch of docs and articles, especially if you're in a good rythm coding, but that comes with time, dont rush the process!
            </div>
            <h2 className='centerDiv'>Check out some of the other stuff I've worked on!</h2>
            <Link to="/pastprojects" className='backButton'>Past Projects</Link>   
        </div>
    )
}

export default AboutMe