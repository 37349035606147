import React from 'react';
import "./StickersPage.css"

// Helper function to import all images from the stickers directory
const importAll = (requireContext) => {
    return requireContext.keys().map(requireContext);
};

// Importing all images from the stickers directory
const images = importAll(require.context('./Stickers', false, /\.(png|jpe?g|svg)$/));

function StickersPage() {
    return (
        <div className="stickers-container">
            {images.map((image, index) => (
                <img key={index} src={image} alt={`sticker-${index}`} />
            ))}
        </div>
    );
}

export default StickersPage;


