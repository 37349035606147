import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './PostPage.css'; // Import the CSS file

const PostPage = () => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [link, setLink] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [numEmails, setNumEmails] = useState(0);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleLinkChange = (event) => {
    setLink(event.target.value)
  }

  const handleImageChange = (event) => {
    setImage(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  // submit form 
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      title: title,
      image: image,
      description: description,
      category: category,
      link: link
    };
    try {
      const response = await fetch('https://lilbrobackend-4e1f701d20d5.herokuapp.com/post', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to post data');
      }

      console.log('Data posted successfully');
      // Optionally, clear the form fields after successful submission
      setTitle('');
      setImage('');
      setDescription('');
      setCategory('');
      setLink('');
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  // get number of emails
  useEffect(() => {
    const fetchNumEmails = async () => {
      try {
        const response = await fetch('https://lilbrobackend-4e1f701d20d5.herokuapp.com/getEmailCount')
        console.log(response)
        if (!response.ok) {
          throw new Error('Failed to get data');
        }
        const data = await response.json();
        setNumEmails(data);
      } catch (error) {
        console.error('Error getting data:', error);
      }
    };

    fetchNumEmails();
  }, []);

  return (
    <div className="container"> 
      <div className="form-container"> 
        <h1>What's on your mind, crazy guy?</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              Title:
              <input type="text" value={title} onChange={handleTitleChange} required />
            </label>
          </div>
          <div>
            <label>
              Image URL:
              <input type="url" value={image} onChange={handleImageChange} required />
            </label>
          </div>
          <div>
            <label>
              Link:
              <input type="url" value={link} onChange={handleLinkChange}/>
            </label>
          </div>
          <div>
            <label>
              Description:
              <textarea value={description} onChange={handleDescriptionChange} required />
            </label>
          </div>
          <div>
            <label>
              Category:
              <select value={category} onChange={handleCategory} required>
                <option value="">Select a category</option>
                <option value="comics">Comics</option>
                <option value="music">Music</option>
                <option value="games">Games</option>
                <option value="sports">Sports</option>
                <option value="events">Events</option>
              </select>
            </label>
          </div>
          <div>
            <button type="submit">Submit</button>
          </div>
        </form>
        <h1>
          Number of Subscribers: {numEmails}
        </h1>
        <Link to='/sendemail'>Send an email</Link>
      </div>
    </div>
  );  
};

export default PostPage;
