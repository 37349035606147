import "./About.css"
import rene from "./pics/drowning.png"
import sunflower from "./pics/sunflower.png"
import lilb from "./pics/lilb.png"
import pikmin from "./pics/pikmin.webp"
import { Link } from "react-router-dom"
function AboutVideos(){
    return(
        <div> 
            <h1 className="header">Video Projects</h1>

            <img src={rene} alt='Drowning thumbnail' className="smaller-img"></img>
            <a href="https://youtu.be/gVXgMGX5PkY?si=2W0clurRDfa7NoOs" className="links">Drowning Short Film</a>
            <div>
                My first short film, made it when I was 18.
                Watching it now all I see is a high schooler trying to be edgy haha.
                I still love it though, it was such a fun experience.
                The sound track is crazy good too.
            </div>

            <img src={sunflower} alt="Sunflower Thumbnail" className="smaller-img"></img>
            <a href="https://youtu.be/2BYjwJCNtsg?si=fGNWKAyzNbSl_j1b" className="links">Sunflower Claymation</a>
            <div>
                I had a little phase where I was obsessed with stop motion animation, it was one of those random covid hobbies you know?
                I remember buying a bunch of clay and wire at hobby lobby and getting home and realizing I had no idea how to do any of this.
                The script was actually written after we (shoutout valentine) made the characters, we just went with what we thought looked cool.
                I think it came out pretty solid. This one was actually cheaper to make than the drowning short film lol
            </div>

            <img src={lilb} alt="Lil B Thumbnail" className="smaller-img"></img>
            <a href="https://youtu.be/P_ybEr41Owc?si=R4h-UjLfPzTBFMiv" className="links">My friend (Feat. Lil B)</a>
            <div>
                Yeah thats a real feature from Lil B the based god.
                I had just signed to my friends label and saw a tweet that said if you ask Lil B nicely he'll work with any artist on any budget. 
                The label paid for the feature, got me the beat, and put the song on spotify and apple music.
                All I had to worry about was messaging Lil B (harder than it sounds LOL)
                and working on a music video for the song. I think it came out pretty dope, you should watch it.
            </div>

            <img src={pikmin} alt="Smash thumbnail" className="smaller-img"></img>
            <a href="https://youtu.be/0eFNE_lpSuk?si=H2sU2BFEtdgcSGwq" className="links">30 Minutes of smash clips</a>
            <div>
                About 2 years worth of clips I gathered while getting every character in the game in elite smash.
                I wanted this to be the kind of video you play in the background while you study or eat or something.
                Crazy good music too. I love this game so much.
            </div>
            <Link to='/pastprojects' className="links">Back</Link>
        </div>
    )
}

export default AboutVideos