import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./MusicPage.css"

function MusicPage() {
  const [musicData, setMusicData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedMusic, setSelectedMusic] = useState(null);

  useEffect(() => {
    const fetchMusicData = async () => {
      try {
        const response = await fetch('https://lilbrobackend-4e1f701d20d5.herokuapp.com/getMusic');

        if (!response.ok) {
          throw new Error('Failed to get data');
        }

        const data = await response.json();
        setMusicData(data.reverse());
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMusicData();
  }, []);

  const handleMusicClick = (index) => {
    setSelectedMusic(JSON.parse(musicData[index]));
  };

  // Render loading state while fetching data
  if (!musicData && !error) {
    return <div>Loading...</div>;
  }

  // Render error message if fetching data failed
  if (error) {
    return <div>Error: {error}</div>;
  }

  // Render Music data
  return (
    <div className="centerMusic">
      {selectedMusic ? (
        <div>
          <h1>{selectedMusic.title}</h1>
          <div className='musicframe'>
            <img
              src={selectedMusic.image}
              alt={selectedMusic.title}
            />
          </div>
          <div className="description">
            {selectedMusic.description
              .split('\n')
              .map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
          </div>
          {selectedMusic.link !== '' && (
            <a href={selectedMusic.link} alt='More Information'>
              Click here for more!
            </a>
          )}
          <div/>
          <button onClick={() => setSelectedMusic(null)}>Back</button>
        </div>
      ) : (
        <div>
          {musicData.map((music, index) => {
            const parsedMusic = JSON.parse(music);
            return (
              <button className='musicButton' key={index} onClick={() => handleMusicClick(index)}>
                {parsedMusic.title}
              </button>
            );
          })}
          <Link to='/'>Back</Link>
        </div>
      )}
    </div>
  );
  
}

export default MusicPage;
