import "./About.css"
// import getclose from "./pics/getclose.png"
import monsterjam from "./pics/monsterjam.png"
import outhere from "./pics/outhere.png"
import getclose from "./pics/getclose.png"
import { Link } from "react-router-dom"
function AboutMusic(){
    return(
        <div>
            <h1 className="header">
                Old music, lol
            </h1>

            <div>
                I was 15 when I made my first song on soundcloud. I honestly still think its pretty good (shoutout cris bolts)
                I even performed and put together events at places like a tattoo shop, wet and wild water park, and a book store in downtown el paso with a venue setup in the basement.
            </div>
            <div className="links">Here's some of the stuff that survived the "make song private" button.</div>

            <img src={outhere} alt="Out here in shambles" className="smaller-img"></img>
            <div/>
            <a href="https://soundcloud.com/reptileperson/outhereinshambles?si=d544a042046e495ca408254b1ed569d5&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" className="links">Out here (In Shambles)</a>
            <div>
                Made this after listening to Lost in the world by Kanye West.
                I had a couple years of experience making beats at this point. 
                I tried to capture the slow rise in intensity, then super intense, then sudden cut to the outro flow that LITW has.
                I slowly added a ton of sounds and cut them all off for the outro. 
                Ill let you to decide if I did a good job or not.
            </div>

            <img src={getclose} alt="Get Close" className="smaller-img"></img>
            <div/>
            <a href="https://soundcloud.com/reptileperson/sets/get-close?si=6a1c65e47248474ebd9177089bac32db&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" className="links">Get Close</a>
            <div>
                I think its so funny hearing myself in this song, but it was my first song to hit 8k plays on soundcloud!
                I actually found the beat before writing the song, but I knew I wasnt gonna be able to do the hook correctly.
                Tatiana definitely steals the show shes great in this. 
                I remember making the song private and getting a DM from a fan from Seattle asking me to make the song available again.
                Pretty cool idk I'm glad that person liked it.
            </div>


            <img src={monsterjam} alt="Monster Jam" className="smaller-img"></img>
            <div/>
            <a href="https://soundcloud.com/reptileperson/monster-jam?si=2f157bc29ee243a98c01d2a39ac10000&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" className="links">Monster Jam</a>
            <div>
                First song in like 5 years. I stopped doing the music thing while I worked on my other projects.
                I made this recently, I think I did it to see if it was still fun to rap, its a silly song with heavy action bronson influences in the lyrics and 
                west side gunn in the beat. I think I killed it idk
            </div>
            <Link to='/pastprojects' className="links">Back</Link>
        </div>
    )
}

export default AboutMusic