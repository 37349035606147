import "./About.css"
import sunflowerShirt from "./pics/sunflowerShirts.jpg"
import ispy from "./pics/ispy.jpg"
import beanies from "./pics/beanies.jpg"
import nes from "./pics/nes.png"
import { Link } from "react-router-dom"
function AboutClothes(){
    return(
        <div>
            <h1 className="header">
                Sunflower/Freestyled Clothing 
            </h1>

            <img src={ispy} alt="Ispy book" class="smaller-img"></img>
            <a href="https://www.dropbox.com/sh/pmpvkhmqv748qaf/AAA_GTD-ZwsqWqcjxLHNz7s2a?dl=0" className="links">Ispy book</a>
            <div>
                Remember those "Can you see what I see" books from elementary school? Or even Wheres waldo? Everyone remembers Waldo.
                This is kind of like that, I met with some of the coolest collectors I know and tried to bring a familiar, nostalgic vibe to one of my very first projects.
                This project was never fully realized, I wasn't able to get any physical copies made, maybe one day.
                The link has some of the first photoshoots I "directed" I guess? It kind of led to the way we got the photoshoots done for the sunflower shoot.
                Sometimes its cool to look back at the early stuff.
            </div>

            <img src={sunflowerShirt} alt="Sunflower Shirts" class="smaller-img"></img>
            <a href="https://www.dropbox.com/scl/fo/yel644r5w6waq1fpyokd2/AHqYqdorbNUczmovodvTBsc?rlkey=fqum8j3lb560oz5qeyzkkfmzy&dl=0" className="links">Sunflower Photoshoot</a>
            <div>
                Photoshoot for my first clothing brand, Sunflower. 
                It was a follow up to the claymation I had put out that had just gotten over 1000 views on youtube, I was so hyped.
                Everything about this project was incredibly successful I felt so much support from not just my friends but strangers in the community, it was awesome.
                I think you can see it in the photoshoot, everyone was just having fun, nobody cared how many shirts we sold.
                Theres a couple extra doodles in that file too, early sketches of the characters, early set photo, stuff like that.
                I also had clay jewelery made for the short film, you can see the models in the pictures wearing the necklaces and earrings we made.
            </div>

            <img src={beanies} alt="beanies" class="smaller-img"></img>
            <a href="https://www.dropbox.com/scl/fo/2kl6t44fzkxiajkb7fdqf/ADddxJOZ02fL2Az8OwImlBw?rlkey=y30a34vb1jfmm7oc5f8honcuy&dl=0" className="links">Freestyled Drop #1</a>
            <div>
                I took some time off from Sunflower and decided I wanted to work on something a little less "sunflowery" if that makes sense?
                It felt like I was milking the claymation thing and I didnt want to be doing that, so I rebranded to Freestyled and pursed a more streetwear, skater type of brand.
                The first release was a series of colored beanies, inspired by Mr. Game and watch (its actually his down smash move, I got rid of the hammers).
                I actually dont have any of these, it makes me kinda sad. Maybe I'll restock one day idk.
                The shoot for this wasn't as well thought out as the ones before or after, I just knew I wanted every model in front of a blank white sheet
                but I wasnt sure where to take it from there. Shoutout to danny for going crazy with the editing on these.
            </div>

            <img src={nes} alt="nes shirt" className="smaller-img"></img>
            <a href="https://www.dropbox.com/scl/fo/8hjg9tzaazelngpdo6qlw/AHmy4AVT-9ABRtaQsDSPw7w?rlkey=hzixmb8bbw54gr8zbaouhb6cl&dl=0" className="links">Freestyled Drop #2</a>
            <div>
                The second release in the Freestyled line, clearly still inspired by super smash bros.
                I wanted the Freestyled drops to stay consistent with the early 2000s vibe. 
                Its not exactly a smash inspiration, its more of a gamecube inspiration.
                I wanted his face to look like he had just eaten some of that super sour tearjerker candy from back in the day.
                For the photoshoot, I had already experimented with setting, text, and models. This time I wanted to take it back to basics and just take pictures of the shirts.
                First photoshoot I did on my own instead of getting a photographer, pretty proud of how they came out. 
            
            </div>
            <Link to='/pastprojects' className="links">Back</Link>
        </div>


    )
}

export default AboutClothes